import { filter, isEmpty, orderBy } from "lodash";
import React, { Component } from "react";
import { Accordion, Badge, Card, Nav, Tab } from 'react-bootstrap';
// reponsive
import MediaQuery from "react-responsive";
import {
  classProducerName, maxWidthValue,
  minWidthValue, voteNoChangedEmploymentCode, voteNoPendingCode, voteNoWaitListCode
} from "../../helpers/constant";
import CustomLoader from '../atoms/CustomLoader';
import PendingAndWaitMemberList from "../molecules/PendingAndWaitMemberList";
import { getUserInfo } from "../../helpers/utilCommon"

class SummaryTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: 'pendingApplications',
      activeTabKeyMobile: '',
      userInfo:null
    };
  }

  componentDidMount(){
    this.setState({
        userInfo:getUserInfo()
    })
}

  handleTabChange = (eventKey) => {
    this.setState({ activeTabKey: eventKey });
  }

  handleClick = (eventKey) => {
    this.setState({ activeTabKeyMobile: eventKey });
  };

  renderMobileLoader = () => (
    <div className='leftPane-loader txtCenter vMiddle'>
      <CustomLoader isVisible={false} />
    </div>
  );

  render() {
    const {
      isMembersLoading,
      memberList,
      committeeMemberList,
      selectedCommitteeWithPermission,
      committeeClassificationList,
      voteList
    } = this.props;
    const { activeTabKey, activeTabKeyMobile, userInfo } = this.state;


    const { committeemembers } = committeeMemberList;

    let pendingApplicationMembers = filter(committeemembers, member => {
      if (
        member.voteCode === voteNoPendingCode ||
        member.voteCode === voteNoChangedEmploymentCode
      ) {
        return member;
      }
    });
    pendingApplicationMembers = orderBy(
      pendingApplicationMembers,
      ["joindate"],
      ["asc"]
    );
    let waitMembers = filter(committeemembers, member => {
      if (
        member.classsificationName === classProducerName &&
        member.voteCode === voteNoWaitListCode
      ) {
        return member;
      }
    });
    waitMembers = orderBy(waitMembers, ["joindate"], ["asc"]);

    const iscommitteeclassified = isEmpty(selectedCommitteeWithPermission)
      ? false
      : selectedCommitteeWithPermission.iscommitteeclassified;

    return (
      <>
        <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
          {isMembersLoading ? (
            <div
              className="summar-loader-view leftPane-loader txtCenter vMiddle"
              centered='true'
              columns={1}
            >
              <div>
                <CustomLoader isVisible={false} />
              </div>
            </div>
          ) : (
            <Tab.Container activeKey={activeTabKey} onSelect={this.handleTabChange}>
              <Nav variant="tabs" as="ul" className="astm-nav-tabs" id="pwTab" role="tablist" data-testid="SummaryTabsCmp">
                <Nav.Item as="li">
                  <Nav.Link as="a" data-testid="PendingApplications" eventKey="pendingApplications" href="javascript:void(0)" id="pendingApplications" data-toggle="tab" role="tab" aria-controls="pendingApplications" aria-selected="true">
                    Pending Applications <Badge className="astm-badge bg-danger">{pendingApplicationMembers.length}</Badge>
                  </Nav.Link>
                </Nav.Item>

                {iscommitteeclassified &&
                  <Nav.Item as="li">
                    <Nav.Link as="a" data-testid="ProducerWaitList" eventKey="producerWaitList" href="javascript:void(0)" id="producerWaitList" data-toggle="tab" role="tab" aria-controls="producerWaitList" aria-selected="true">
                      Producer Wait List <Badge className="astm-badge bg-warning">{waitMembers.length}</Badge>
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>

              <Tab.Content id="pwTabContent-alt">
                <Tab.Pane eventKey="pendingApplications">
                  <PendingAndWaitMemberList
                    memberList={memberList}
                    filteredMembers={pendingApplicationMembers}
                    selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                    committeeClassificationList={committeeClassificationList}
                    voteList={voteList}
                    tabName="pending"
                    userInfo={userInfo}
                    {...this.props}
                  />
                </Tab.Pane>

                {iscommitteeclassified &&
                  <Tab.Pane eventKey="producerWaitList">
                    <PendingAndWaitMemberList
                      memberList={memberList}
                      filteredMembers={waitMembers}
                      selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                      committeeClassificationList={committeeClassificationList}
                      voteList={voteList}
                      tabName="waitlist"
                      userInfo={userInfo}
                      {...this.props}
                    />
                  </Tab.Pane>
                }
              </Tab.Content>
            </Tab.Container>
          )}
        </MediaQuery>

        <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
          <Accordion className="astm-accordion astm-accordion--full-width" activeKey={activeTabKeyMobile} onSelect={this.handleClick}>
            <Card className="astm-card">
              <Card.Header>
                <Accordion.Toggle as={Card} eventKey="pendingApplications">
                  <h2 className="mb-0">
                    <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" aria-expanded="true" >
                      Pending Applications
                      {isMembersLoading ? null : (<span className="astm-badge bg-danger badge">{pendingApplicationMembers.length}</span>)}

                    </button>
                  </h2>
                </Accordion.Toggle>
              </Card.Header>

              <Accordion.Collapse eventKey="pendingApplications">
                <Card.Body>
                  {isMembersLoading ? (
                    this.renderMobileLoader()
                  ) : (
                    <PendingAndWaitMemberList
                      memberList={memberList}
                      filteredMembers={pendingApplicationMembers}
                      selectedCommitteeWithPermission={
                        selectedCommitteeWithPermission
                      }
                      committeeClassificationList={committeeClassificationList}
                      voteList={voteList}
                      tabName="pending"
                      userInfo={userInfo}
                      {...this.props}
                    />
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            {!iscommitteeclassified ? null : (
              <Card className="astm-card">
                <Card.Header>
                  <Accordion.Toggle as={Card} eventKey="producerWaitList">
                    <h2 className="mb-0">
                      <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" aria-expanded="true" >
                        Producer Wait List
                        {isMembersLoading ? null : (<span className="astm-badge bg-warning badge">{waitMembers.length}</span>)}

                      </button>
                    </h2>

                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="producerWaitList">
                  <Card.Body>
                    {isMembersLoading ? (
                      this.renderMobileLoader()
                    ) : (
                      <PendingAndWaitMemberList
                        memberList={memberList}
                        filteredMembers={waitMembers}
                        selectedCommitteeWithPermission={
                          selectedCommitteeWithPermission
                        }
                        committeeClassificationList={committeeClassificationList}
                        voteList={voteList}
                        tabName="waitlist"
                        userInfo={userInfo}
                        {...this.props}
                      />
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          </Accordion>
        </MediaQuery>
      </>
    );
  }
}
export default SummaryTabs;
