/* eslint-disable react/no-unescaped-entities */
import logo_primary_astm_tagline from "astm-ux-design-system/dist/assets/img/ASTM logo_primary_astm_tagline.png";
import { get } from "lodash";
import React, { Suspense } from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { decryptData } from "../../../helpers/utilCommon";
import { signOut } from "../../../redux/login/actions";
import SearchBox from "../../atoms/SearchBox";
import IdentityDelegationBanner from "../identityDelegationBanner";
import {
  IDENTITY_DELEGATION_FLAG,
  USER_INFO,
} from "../../../models/common.models";
import TopMenu from "./TopMenu";

const WCMSHeader = React.lazy(() => import("../WCMS/WCMSHeader"));
const WCMSTopMenu = React.lazy(() => import("../WCMS/WCMSTopNavMenu"));

const HeaderComponent = (props) => {
  const { userInfo, featureFlag } = props;
  const identityDelegation = userInfo?.IdentityDelegation
    ? userInfo?.IdentityDelegation
    : decryptData(USER_INFO)
    ? get(decryptData(USER_INFO), IDENTITY_DELEGATION_FLAG)
    : null;
  return (
    <header data-testid="header-component">
      <div className="container">
        {!featureFlag?.wcmsnavintegration ? (
          <div className="headerWrap">
            <div className="logoWrap">
              <a
                href={`${process.env.REACT_APP_PUB_URL}`}
                target="_blank"
                aria-label="ASTM INTERNATIONAL Logo"
              >
                <Image
                  src={logo_primary_astm_tagline}
                  alt="ASTM INTERNATIONAL Logo"
                />
              </a>
            </div>
            <div className="headerFilterhWrap">
              <div className="searchWrap">
                <SearchBox />
              </div>
            </div>
          </div>
        ) : (
          <Suspense>
            <WCMSHeader {...props} />
          </Suspense>
        )}
      </div>

      {featureFlag?.wcmsnavintegration ? (
        <Suspense>
          <WCMSTopMenu path={process.env.REACT_APP_WCMS_AWS_HEADER_PATH} />
        </Suspense>
      ) : (
        <TopMenu {...props} />
      )}
      {identityDelegation && (
        <IdentityDelegationBanner
          {...identityDelegation}
          accountNo={userInfo.accountNumber}
        />
      )}
    </header>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    userInfo: user.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut(dispatch)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderComponent));
