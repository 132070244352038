import { clone, filter, find, findIndex, isEmpty, isEqual, map, orderBy, slice } from 'lodash';
import React, { Component } from 'react';
import { Accordion, OverlayTrigger, Tooltip as TooltipBootstrap } from 'react-bootstrap';
import { connect } from "react-redux";
// reponsive
import MediaQuery from "react-responsive";
import {
    classUnClassifiedName, maxWidthValue,
    minWidthValue, officialVoteOptionsAsPerClassifications, voteNoChangedEmploymentCode,
    voteNoChangedEmploymentId, voteNoPendingCode, voteNoPendingId
} from '../../helpers/constant';
import { getFormatDate, getUserInfo, memberNameFormat } from '../../helpers/utilCommon';
import { setRosterSortOrder } from '../../redux/filterValues/actions';
import { classificationUpdate, fetchComitteeMemberDetail, memberOfficialVoteUpdate } from '../../services/rosterService';
import CustomToggle from '../atoms/CustomToggle';
import Pager from '../atoms/Pager';
import RenderSelect from '../atoms/Select';
import SortByMobile from '../atoms/SortByMobile';
import TableHeader from '../atoms/TableHeader';
import ConfirmationPopUp from './ConfirmationPopUp';
import MemberCardPopUp from './MemberCardPopUp';
import { IdentityDelegationDropdown } from '../atoms/IdentityDelegationDropdown';

const componentOriginName = 'member-list-table';

class MemberTableComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            committeemembers: props.committeeMemberList,
            rosterOrderObj: null,
            sortedCommitteeMembers: props.committeeMemberList,
            totalrecords: 0,
            totalPages: 1,
            activePage: 1,
            itemPerPage: 25,
            itemSkip: 0,
            lastSortedKey: 'name',
            showPopup: {
                status: false,
                memberid: null,
                committeeid: null,
                leftPosition: '0px',
                topPosition: '0px'
            },
            confirmationPopup: {
                confirmationType: '',
                memberid: null,
                isLoading: false,
                showPopup: false,
                confirmationMessage: '',
                data: null,
                topPosition: '0px',
                leftPosition: '0px'
            },
            selectedAccordianKey: null,
            userInfo:null
        };
    }

    componentDidMount(){
        this.setState({
            userInfo:getUserInfo()
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { committeeMemberList, rosterOrderObj } = nextProps;
        const { itemPerPage } = prevState;
        if (!isEmpty(committeeMemberList)) {
            const { committeemembers, totalrecords } = committeeMemberList;
            if (!(isEqual(committeemembers, prevState.committeemembers) &&
                isEqual(rosterOrderObj, prevState.rosterOrderObj))) {
                let totalPages = Math.ceil(totalrecords / itemPerPage);
                let sortedCommitteeMembers = committeemembers;
                let lastSortedKey = 'name';
                if (rosterOrderObj) {
                    let { itemName, order_By } = rosterOrderObj;
                    if (itemName === 'name') {
                        if (order_By === 1) {
                            sortedCommitteeMembers = orderBy(committeemembers, [
                                member => member.lastname ? member.lastname.toLowerCase() : null,
                                member => member.firstname ? member.firstname.toLowerCase() : null
                            ], ['asc', 'asc']);
                        } else if (order_By === 2) {
                            sortedCommitteeMembers = orderBy(committeemembers, [
                                member => member.lastname ? member.lastname.toLowerCase() : null,
                                member => member.firstname ? member.firstname.toLowerCase() : null
                            ], ['desc', 'desc']);
                        }
                    } else {
                        if (order_By === 1) {
                            sortedCommitteeMembers = orderBy(committeemembers, [
                                member => member[itemName] ? member[itemName].toLowerCase() : null
                            ], ['asc']);
                        } else if (order_By === 2) {
                            sortedCommitteeMembers = orderBy(committeemembers, [
                                member => member[itemName] ? member[itemName].toLowerCase() : null
                            ], ['desc']);
                        }
                    }
                }
                return {
                    committeemembers,
                    rosterOrderObj,
                    totalrecords,
                    totalPages,
                    activePage: 1,
                    itemSkip: 0,
                    lastSortedKey,
                    sortedCommitteeMembers
                };
            }
        }
        return null;
    }

    onChangeItemPerPage = (itemPerPage) => {
        const { totalrecords } = this.state;
        let totalPages = Math.ceil(totalrecords / itemPerPage);
        this.setState({ itemPerPage, totalPages, activePage: 1, itemSkip: 0 });
    }

    onPageChangeHandler = (e, page) => {
        const { itemPerPage } = this.state;
        let itemSkip = (page - 1) * itemPerPage;
        this.setState({ activePage: page, itemSkip });
    }

    handleClickHeader = (itemName, order_By) => {
        let orderObj = { order_By, itemName };
        this.props.setRosterSortOrder(orderObj);
    }

    handleAccordianKey = (key) => {
        this.setState({ selectedAccordianKey: key });
    }

    renderMemberInfo = (displayCommitteemembers) => {
        const { selectedCommitteeWithPermission, committeeClassificationList, voteList } = this.props;
        let iscommitteeclassified = -1
        if (!isEmpty(selectedCommitteeWithPermission)) {
            iscommitteeclassified = selectedCommitteeWithPermission.iscommitteeclassified ? 1 : 0;
        }

        let classificationOptions = map(committeeClassificationList, (classObj) => ({
            key: classObj.committeeclassificationid + classObj.name,
            value: classObj.committeeclassificationid,
            text: classObj.name
        }));

        return map(displayCommitteemembers, (member) => {
            const { email, classsificationName, classsificationColor, voteCode } = member;
            const nameFormat = memberNameFormat(member.firstname, member.middlename, member.lastname, 2);
            const joinDate = getFormatDate(member.joindate);

            let isEditClassification = -1;
            let isEditVote = -1;
            if (iscommitteeclassified !== -1) {
                isEditClassification = selectedCommitteeWithPermission.updateClassification ? 1 : 0;
                isEditVote = selectedCommitteeWithPermission.updateVoting ? 1 : 0;
            }
            let editClassSelectBoxColor = "c8c8c8";
            let editVoteSelectBoxColor = "c8c8c8";
            if (classsificationName && voteCode && classsificationColor) {
                if (classsificationName !== classUnClassifiedName) {
                    editClassSelectBoxColor = classsificationColor.substr(1);
                    if (!(voteCode === voteNoPendingCode || voteCode === voteNoChangedEmploymentCode)) {
                        editVoteSelectBoxColor = classsificationColor.substr(1);
                    }
                }
            }
            const editClassSelectBoxClassName = "custom-select-" + editClassSelectBoxColor;
            const editVoteSelectBoxClassName = "custom-select-" + editVoteSelectBoxColor;

            const comitteeType = iscommitteeclassified ? 'classified' : 'unclassified';
            let allowedVoteOptions = [];
            for (let i = 0; i < officialVoteOptionsAsPerClassifications.length; i++) {
                if (officialVoteOptionsAsPerClassifications[i].classificationId === classsificationName) {
                    allowedVoteOptions = officialVoteOptionsAsPerClassifications[i][comitteeType];
                    break;
                }
            }
            let filteredVoteList = filter(voteList, (voteObj) => {
                if (allowedVoteOptions.indexOf(voteObj.code) !== -1) {
                    return true;
                }
                return false;
            });
            let officialVoteOptions = map(filteredVoteList, (voteObj) => ({
                key: voteObj.voteid + voteObj.description,
                value: voteObj.voteid,
                text: voteObj.description
            }));
            const { selectedAccordianKey, showPopup } = this.state;

            return (
                <React.Fragment key={member.memberid}>
                    <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
                        <tr key={member.memberid}>
                            <td id={"idtd_" + member.memberid} className="name">
                                <div className="position-relative">
                                    <p className="astm-type-body mb0">
                                        <OverlayTrigger placement="top" overlay={<TooltipBootstrap className={showPopup.status ? 'invisible' : ''} id={"id_" + member.memberid}>{nameFormat}</TooltipBootstrap>}>

                                            <a
                                                data-testid="nameFormatLink"
                                                id={"id_" + member.memberid}
                                                className="astm-type-body astm-link mb0"
                                                href="javascript:void(0)"
                                                onClick={() => {
                                                    this.handlePopupView(true, member.memberid, member.committeeid, member.memberid, false);
                                                }}
                                            >{this.trimTextEllip(nameFormat, 15)}</a>


                                        </OverlayTrigger>
                                    </p>

                                </div>
                                {this.renderEmail(email, member.memberid)}
                            </td>
                            <td id={"orgtd_" + member.memberid} className="organization">
                                {this.renderOrganization(member.organization, member.memberid)}
                            </td>
                            <td className="joinDate">{joinDate}</td>
                            <td className="classification">
                                {this.renderEditClassification(isEditClassification, classificationOptions, editClassSelectBoxClassName, member)}
                            </td>
                            <td className="OfficialVote">
                                {this.renderEditVote(isEditVote, officialVoteOptions, editVoteSelectBoxClassName, member)}
                            </td>
                        </tr>
                    </MediaQuery>

                    <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
                        <tr>
                            <td className="astm-table-td astm-table-td--mobile">
                                <div className="astm-table-td--mobile-content">
                                    <div>
                                        <span className="table-header astm-type-body bold">Name: </span>
                                        <span className="table-data astm-type-body">
                                            <a className="astm-type-body astm-link" href='#' onClick={() => { this.handlePopupView(true, member.memberid, member.committeeid, null, true); }}>{nameFormat}</a>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="table-data astm-type-body">
                                            <a className="astm-type-body astm-link" href={"mailto:" + email}>{email}</a>
                                        </span>
                                    </div>
                                    <div>
                                        <span className="table-header astm-type-body bold">Organization: </span>
                                        <span className="table-data astm-type-body">{member.organization}</span>
                                    </div>
                                    <div>
                                        <span className="table-header astm-type-body-bold bold">Join Date: </span>
                                        <span className="table-data astm-type-body">{joinDate}</span>
                                    </div>
                                    <CustomToggle
                                        selectedAccordianKey={selectedAccordianKey}
                                        setAccordianKey={this.handleAccordianKey}
                                        eventKey={'key_' + member.memberid}
                                        as='h2'
                                        className='mb-0 inlineBlock'
                                        collapseChild={(
                                            <button className="btn astm-btn btn-link padding0" type="button">
                                                <span>Show More </span>
                                                <i className="fas astm-icon fa-chevron-down"></i>
                                            </button>
                                        )}
                                        expendChild={(
                                            <button className="btn astm-btn btn-link padding0" type="button">
                                                <span>Show Less </span>
                                                <i className="fas astm-icon fa-chevron-up"></i>
                                            </button>
                                        )}
                                    />
                                    <Accordion.Collapse eventKey={'key_' + member.memberid}>
                                        <>
                                            <div className="heading-row">
                                                <div className="heading-label astm-type-body">Classification</div>
                                                <div className="heading-content astm-type-body">
                                                    {this.renderEditClassification(isEditClassification, classificationOptions, editClassSelectBoxClassName, member)}
                                                </div>
                                            </div>
                                            <div className="heading-row">
                                                <div className="heading-label astm-type-body">Official Vote</div>
                                                <div className="heading-content astm-type-body">
                                                    {this.renderEditVote(isEditVote, officialVoteOptions, editVoteSelectBoxClassName, member)}
                                                </div>
                                            </div>
                                        </>
                                    </Accordion.Collapse>
                                </div>
                            </td>
                        </tr>
                    </MediaQuery>
                </React.Fragment>
            );
        });
    }

    trimTextEllip = (str, len) => {
        if (isEmpty(str)) {
            return '';
        }

        let trimStr = str.trim();
        return trimStr.length > len ? trimStr.substr(0, len) + '...' : trimStr;
    }
    renderEmail = (email, memberid) => {
        if (isEmpty(email)) {
            return null;
        }
        return (
            <OverlayTrigger placement="top" overlay={<TooltipBootstrap id={email}>{email}</TooltipBootstrap>}>
                <p className="astm-type-body"><a className="astm-type-body astm-link" href={"mailto:" + email}>{this.trimTextEllip(email, 14)}</a></p>
            </OverlayTrigger>
        )
        const tdSelector = document.getElementById("idtd_" + memberid);
        if (!isEmpty(tdSelector)) {
            const textMaxWidth = tdSelector.offsetWidth - 20;

            const dummyText = document.createElement("a");
            dummyText.innerHTML = email;
            dummyText.setAttribute("id", "dummy_email_txt_" + memberid);
            dummyText.setAttribute("style", "position: absolute;");
            tdSelector.appendChild(dummyText);

            const dummyTextSelector = document.getElementById("dummy_email_txt_" + memberid);

            let isTruncated = true;
            if (dummyTextSelector.offsetWidth <= textMaxWidth) {
                isTruncated = false;
            }
            dummyTextSelector.parentNode && dummyTextSelector.parentNode.removeChild(dummyTextSelector);

            if (isTruncated) {
                return (
                    <OverlayTrigger placement="top" overlay={<TooltipBootstrap id={email}>{email}</TooltipBootstrap>}>
                        <p className="astm-type-body"><a className="astm-type-body astm-link" href={"mailto:" + email}>{email}</a></p>
                    </OverlayTrigger>
                );
            }
            return (
                <p className="astm-type-body"><a className="astm-type-body astm-link" href={"mailto:" + email}>{email}</a></p>
            );
        }
        return (
            <p className="astm-type-body"><a className="astm-type-body astm-link" href={"mailto:" + email}>{email}</a></p>
        );
    }

    renderOrganization = (organization, memberid) => {
        if (isEmpty(organization)) {
            return null;
        }
        return (
            <OverlayTrigger placement="top" overlay={<TooltipBootstrap id={organization}>{organization}</TooltipBootstrap>}>
                <p className="astm-type-body block-ellipsis breakWord">{organization}</p>
            </OverlayTrigger>
        );
        const tdSelector = document.getElementById("orgtd_" + memberid);
        if (!isEmpty(tdSelector)) {
            const textMaxHeight = 45;

            const dummyText = document.createElement("div");
            dummyText.innerHTML = organization;
            dummyText.setAttribute("id", "dummy_org_txt_" + memberid);
            tdSelector.appendChild(dummyText);

            const dummyTextSelector = document.getElementById("dummy_org_txt_" + memberid);

            let isTruncated = true;
            if (dummyTextSelector.offsetHeight <= textMaxHeight) {
                isTruncated = false;
            }
            dummyTextSelector.parentNode && dummyTextSelector.parentNode.removeChild(dummyTextSelector);

            if (isTruncated) {
                return (
                    <OverlayTrigger placement="top" overlay={<TooltipBootstrap id={organization}>{organization}</TooltipBootstrap>}>
                        <p className="astm-type-body block-ellipsis">{organization}</p>
                    </OverlayTrigger>
                );
            }
            return (
                <p className="astm-type-body block-ellipsis">{organization}</p>
            );
        }
        return (
            <p className="astm-type-body block-ellipsis">{organization}</p>
        );
    }

    renderEditClassification = (isEditClassification, classificationOptions, editClassSelectBoxClassName, member) => {
        if (isEditClassification === -1) return null;
        const { memberid, committeeclassificationid, classsificationName } = member;
        const { confirmationPopup } = this.state;
        if (isEditClassification) {
            if (isEmpty(classificationOptions)) return null;
            const { loaders } = this.props;
            let updatingField = !isEmpty(loaders[memberid]) && loaders[memberid].isShow ? loaders[memberid] : null;
            let validatingField = (confirmationPopup.confirmationType === 'classification' && confirmationPopup.isLoading && confirmationPopup.memberid === memberid) ? true : false;
            return (
                <IdentityDelegationDropdown
                    size="sm"
                    name="classification"
                    className={"dropdown-loader " + editClassSelectBoxClassName}
                    options={classificationOptions}
                    onChange={(value) => { this.validateClassificationUpdate(value, memberid) }}
                    value={updatingField && updatingField.inputType === 'class' ? updatingField.value : committeeclassificationid}
                    loading={Boolean(updatingField) || validatingField}
                    disabled={Boolean(updatingField)}
                />
            );
        }
        return (
            <span>{classsificationName ? classsificationName : "Not Selected"}</span>
        );
    }

    renderEditVote = (isEditVote, officialVoteOptions, editVoteSelectBoxClassName, member) => {
        if (isEditVote === -1) return null;
        const { memberid, classsificationName, voteid, voteDescription } = member;
        const { selectedCommitteeWithPermission } = this.props;
        const { iscommitteeclassified } = selectedCommitteeWithPermission;
        const { userInfo } =this.state
        if (isEditVote) {
            if (iscommitteeclassified && classsificationName === classUnClassifiedName) {
                let votesOptions = [];
                if (voteDescription) {
                    votesOptions.push({ 'key': voteid, 'value': voteid, 'text': voteDescription });
                }

                return (
                    <>
                    {
                        userInfo?.IdentityDelegation?.permission === "view" ?
                        <span>
                            <IdentityDelegationDropdown
                                size="sm"
                                name="votes"
                                style={{ pointerEvents: 'none' }}
                                disabled
                                className={"dropdown-loader " + editVoteSelectBoxClassName}
                                options={votesOptions}
                                value={voteid}
                            />
                        </span> :
                        <OverlayTrigger placement="top"
                            overlay={<TooltipBootstrap id={'unclassification_note_' + memberid}>You must select a classification before assigning a vote</TooltipBootstrap>}>
                            <span>
                                <IdentityDelegationDropdown
                                    size="sm"
                                    name="votes"
                                    style={{ pointerEvents: 'none' }}
                                    disabled
                                    className={"dropdown-loader " + editVoteSelectBoxClassName}
                                    options={votesOptions}
                                    value={voteid}
                                />
                            </span>
                        </OverlayTrigger>
                    }
                    </>
                );
            } 
                const { loaders } = this.props;
                let updatingField = !isEmpty(loaders[memberid]) && loaders[memberid].isShow ? loaders[memberid] : null;
                let officialVoteOptionsWithDefaultValue = clone(officialVoteOptions);
                let checkInOptions = find(officialVoteOptions, { value: voteid });
                if (isEmpty(checkInOptions)) {
                    officialVoteOptionsWithDefaultValue.unshift({ 'key': voteid, 'value': voteid, 'text': voteDescription });
                }
                return (
                    <IdentityDelegationDropdown
                        size="sm"
                        className={"dropdown-loader " + editVoteSelectBoxClassName}
                        name="Officialvote"
                        options={officialVoteOptionsWithDefaultValue}
                        onChange={(value) => { this.onChangeVoteHandler(value, memberid); }}
                        value={updatingField && updatingField.inputType === 'vote' ? updatingField.value : voteid}
                        loading={Boolean(updatingField)}
                        disabled={Boolean(updatingField)}
                    />
                );
            
        }
        return (
            <span> {voteDescription ? voteDescription : "Not Selected"}</span >
        );
    }

    onChangeClassHandler = (data) => {
        const { committeeClassificationId, memberid } = data;
        let { memberList } = this.props;
        let updatedMemberIndex = findIndex(memberList.committeemembers, { memberid });
        memberList.committeemembers[updatedMemberIndex].committeeclassificationid = committeeClassificationId;

        const { featureFlag } = this.props;
        let isExcludeStudent = featureFlag?.studentcommitteemanagement;
        let classObj = {
            memberid,
            committeeid: this.props.selectedCommittee,
            committeeClassificationId,
            memberList,
            isExcludeStudent
        };

        this.props.classificationUpdate(classObj);
    }

    checkIsMainComittee() {
        const { selectedCommittee, committeeList } = this.props;
        let selectedComitteeDesignation = false;
        for (let i = 0; i < committeeList.length; i++) {
            if (committeeList[i].committeeid === selectedCommittee) {
                selectedComitteeDesignation = committeeList[i].code;
                break;
            }
        }
        let isMainComittee = (selectedComitteeDesignation.indexOf('.') === -1) ? true : false;
        return isMainComittee;
    }

    checkIsProducer(committeeClassificationId) {
        const { committeeClassificationList } = this.props;
        let isClassificationProducer = false;
        for (let i = 0; i < committeeClassificationList.length; i++) {
            if (committeeClassificationList[i].committeeclassificationid === committeeClassificationId) {
                if (committeeClassificationList[i].name.toLowerCase() === 'producer') {
                    isClassificationProducer = true;
                }
                break;
            }
        }
        return isClassificationProducer;
    }

    validateClassificationUpdate = (committeeClassificationId, memberid) => {
        const { selectedCommitteeWithPermission, selectedCommittee, committeeList } = this.props;
        const { iscommitteeclassified, updateClassification } = selectedCommitteeWithPermission;
        if (iscommitteeclassified && updateClassification && this.checkIsMainComittee() && !this.checkIsProducer(committeeClassificationId)) {
            let leftPosition = '2px';
            let topPosition = '0px';
            this.setState({
                confirmationPopup: {
                    confirmationType: 'classification',
                    isLoading: true,
                    showPopup: false,
                    data: null,
                    topPosition,
                    leftPosition,
                    memberid
                }
            }, () => {
                fetchComitteeMemberDetail({ committeeid: selectedCommittee, memberid }, (errMsg, data) => {
                    if (data) {
                        const { othercommitteinfo } = data[0];
                        const subcomitteeClassificationProducer = [];
                        let comitteeIndex = findIndex(committeeList, { committeeid: selectedCommittee });
                        for (let i = 0; i < othercommitteinfo.length; i++) {
                            let { classification, committeedesignation } = othercommitteinfo[i];
                            if (committeedesignation.indexOf(committeeList[comitteeIndex].code) !== -1 && classification === 'Producer') {
                                subcomitteeClassificationProducer.push(othercommitteinfo[i].committeedesignation);
                            }
                        }
                        if (subcomitteeClassificationProducer.length > 0) {
                            const confirmationMessage = this.prepareConfirmationMessageForClassificationUpdate(subcomitteeClassificationProducer);
                            let tdId = "#idtd_" + memberid;
                            let tdSelector = document.querySelector(tdId);
                            topPosition = tdSelector ? tdSelector.offsetTop - 43 : 0;
                            topPosition += 'px';
                            this.setState({
                                confirmationPopup: {
                                    confirmationType: 'classification',
                                    isLoading: false,
                                    showPopup: true,
                                    confirmationMessage: confirmationMessage,
                                    data: { committeeClassificationId, memberid },
                                    topPosition,
                                    leftPosition,
                                    memberid
                                }
                            });
                        } else {
                            this.setState({
                                confirmationPopup: {
                                    confirmationType: '',
                                    isLoading: false,
                                    showPopup: false,
                                    confirmationMessage: '',
                                    data: null,
                                    topPosition: '0px',
                                    leftPosition: '0px',
                                    memberid: null
                                }
                            });
                            this.onChangeClassHandler({ committeeClassificationId, memberid });
                        }
                    } else {
                        this.setState({
                            confirmationPopup: {
                                confirmationType: '',
                                isLoading: false,
                                showPopup: false,
                                confirmationMessage: '',
                                data: null,
                                topPosition: '0px',
                                leftPosition: '0px',
                                memberid: null
                            }
                        });
                    }
                });
            });
        } else {
            this.onChangeClassHandler({ committeeClassificationId, memberid });
        }
    }

    prepareConfirmationMessageForClassificationUpdate(subcomittees) {
        const confirmationMessage = 'According to ASTM Regulations Section 7 on Classification of Committee members, a member classified on any subcommittee as a producer shall be classified on the Main Committee as a producer. The member is already a producer under ' + subcomittees.join(' , ') + '. Do you want to continue updating the classification?';
        return confirmationMessage;
    }

    onChangeVoteHandler = (voteid, memberid) => {
        let { memberList } = this.props;
        const updatedMemberIndex = findIndex(memberList.committeemembers, { memberid });
        const oldVoteId = memberList.committeemembers[updatedMemberIndex].voteid;
        memberList.committeemembers[updatedMemberIndex].voteid = voteid;

        let updatedTotalApplication = this.props.updatedPendingApplication;

        if ((oldVoteId === voteNoPendingId || oldVoteId === voteNoChangedEmploymentId) &&
            voteid !== voteNoPendingId &&
            voteid !== voteNoChangedEmploymentId) {
            updatedTotalApplication -= 1;
        } else if (oldVoteId !== voteNoPendingId &&
            oldVoteId !== voteNoChangedEmploymentId &&
            (voteid === voteNoPendingId || voteid === voteNoChangedEmploymentId)) {
            updatedTotalApplication += 1;
        }

        const { featureFlag } = this.props;
        let isExcludeStudent = featureFlag?.studentcommitteemanagement;
        let voteObj = {
            memberid,
            committeeid: this.props.selectedCommittee,
            voteid,
            memberList,
            updatedTotalApplication,
            isExcludeStudent
        };

        this.props.memberOfficialVoteUpdate(voteObj);
    }

    handleContentUpdate = (updatedCommitteeid, updatedMemberid, origin) => {
        const { committeeid, memberid } = this.state.showPopup;
        if (committeeid !== updatedCommitteeid || memberid !== updatedMemberid || origin !== componentOriginName) {
            this.handlePopupView(false, null, null, null, false);
        }
    }

    handlePopupView = (value, memberid, committeeid, elementId) => {
        let leftPosition = '0px';
        let topPosition = '0px';
        if (elementId) {
            let spanId = "#id_" + elementId;
            let tdId = "#idtd_" + elementId;
            let spanSelector = document.querySelector(spanId);
            leftPosition = (spanSelector.offsetWidth + 40) + 'px';
            let tdSelector = document.querySelector(tdId);
            topPosition = tdSelector.offsetTop - 43;
        }
        this.setState({
            showPopup: {
                status: value,
                memberid,
                committeeid,
                leftPosition,
                topPosition
            }
        });
    }

    handleConfirm = (data, confirmationType) => {
        this.setState({
            confirmationPopup: {
                confirmationType: '',
                isLoading: false,
                showPopup: false,
                confirmationMessage: '',
                data: null,
                topPosition: '0px',
                leftPosition: '0px',
                memberid: null
            }
        });
        if (confirmationType === "classification") {
            this.onChangeClassHandler(data);
        }
    }

    handleClosePopup = () => {
        this.setState({
            confirmationPopup: {
                confirmationType: '',
                isLoading: false,
                showPopup: false,
                confirmationMessage: '',
                data: null,
                topPosition: '0px',
                leftPosition: '0px',
                memberid: null
            }
        });
    }

    render() {
        const {
            rosterOrderObj,
            totalrecords,
            totalPages,
            activePage,
            itemPerPage,
            itemSkip,
            sortedCommitteeMembers,
            showPopup,
            confirmationPopup
        } = this.state;

        if (isEmpty(sortedCommitteeMembers)) {
            return (
                <span className="astm-type-body">No search results found. Try refining your search using the advanced filter.</span>
            );
        }

        let headerProps = [
            { 'title': 'Name', 'hasSorting': true, 'className': "name", "sortKey": 'name', 'orderBy': 1 },
            { 'title': 'Organization', 'hasSorting': true, 'className': "organization", 'sortKey': 'organization', 'orderBy': 0 },
            { 'title': 'Join Date', 'hasSorting': true, 'className': "joindate", 'sortKey': 'joindate', 'orderBy': 0 },
            { 'title': 'Classification', 'hasSorting': true, 'className': "classsificationName", 'sortKey': 'classsificationName', 'orderBy': 0 },
            { 'title': 'Official Vote', 'hasSorting': true, 'className': "voteDescription", 'sortKey': 'voteDescription', 'orderBy': 0 }
        ];

        if (rosterOrderObj) {
            headerProps[0].orderBy = 0;
            let headerClickedIndex = findIndex(headerProps, { sortKey: rosterOrderObj.itemName });
            if (headerClickedIndex >= 0)
                headerProps[headerClickedIndex].orderBy = rosterOrderObj.order_By;
        }

        let uptoRecord = itemSkip + itemPerPage
        if (uptoRecord > totalrecords) {
            uptoRecord = totalrecords;
        }

        const { selectedCommitteeWithPermission } = this.props;
        let displayCommitteemembers = slice(sortedCommitteeMembers, itemSkip, uptoRecord);
        return (
            <>
                <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
                    <table className="table astm-table table-striped roasterTable" data-testid="MemberTableCmp">
                        <thead>
                            <tr className="astm-table-tr">
                                <TableHeader headerProps={headerProps} onClickHeader={this.handleClickHeader}></TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderMemberInfo(displayCommitteemembers)}
                        </tbody>
                    </table>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
                    <div className="accordion-sort clearfix">
                        <SortByMobile label={'Sort by'} headerProps={headerProps} onClickHeader={this.handleClickHeader} />
                    </div>
                    <Accordion>
                        <table className="table astm-table table-striped roasterTable">
                            <tbody>
                                {this.renderMemberInfo(displayCommitteemembers)}
                            </tbody>
                        </table>
                    </Accordion>
                </MediaQuery>

                {showPopup.status ? (
                    <MemberCardPopUp
                        memberId={showPopup.memberid}
                        committeeId={showPopup.committeeid}
                        selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                        leftPosition={showPopup.leftPosition}
                        topPosition={showPopup.topPosition}
                        onClose={this.handlePopupView}
                        contentUpdated={this.handleContentUpdate}
                        origin={componentOriginName}
                        committeeList={[]}
                    />
                ) : null}

                {
                    confirmationPopup.showPopup ? (
                        <ConfirmationPopUp
                            leftPosition={confirmationPopup.leftPosition}
                            topPosition={confirmationPopup.topPosition}
                            confirmationMessage={confirmationPopup.confirmationMessage}
                            confirmationType={confirmationPopup.confirmationType}
                            data={confirmationPopup.data}
                            handleConfirm={this.handleConfirm}
                            handleClosePopup={this.handleClosePopup}
                        />
                    ) : null
                }

                <section className="pagerWrap clearfix" data-testid="PaginationSection">
                    <div className="pager">
                        <Pager
                            activePage={activePage}
                            totalPages={totalPages}
                            ellipsisCount={1}
                            onPageChange={this.onPageChangeHandler}
                        />
                    </div>
                    <div className="itemPerPage">
                        <RenderSelect
                            size="sm"
                            id="itemPerPage"
                            name="itemPerPage"
                            options={[
                                { key: 25, value: 25, text: 25 },
                                { key: 50, value: 50, text: 50 },
                                { key: 75, value: 75, text: 75 },
                                { key: 100, value: 100, text: 100 }
                            ]}
                            onChange={this.onChangeItemPerPage}
                            value={itemPerPage}
                        />
                        <label htmlFor="itemPerPage" className="itemsPerPage">results per page</label>
                    </div>
                    <div className="totalPage">
                        {itemSkip + 1} - {uptoRecord} of {totalrecords} results
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = state => {
    let { loaders, roster, filters } = state;
    return {
        selectedCommittee: roster.selectedCommittee,
        updatedPendingApplication: roster.updatedPendingApplication,
        loaders,
        rosterOrderObj: filters.rosterOrderObj,
        committeeList: roster.committeeList
    }
};

const mapDispatchToProps = dispatch => ({
    classificationUpdate: value => dispatch(classificationUpdate(value)),
    memberOfficialVoteUpdate: value => dispatch(memberOfficialVoteUpdate(value)),
    setRosterSortOrder: value => dispatch(setRosterSortOrder(value))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberTableComponent);