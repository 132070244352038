import moment from 'moment';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Row, Col } from 'react-bootstrap'
import { excelColumns, inActivityURL, uiDateFormat } from "../../helpers/constant";
import { addDocXOnRequest, generateStudentRosterExcelData, getCurrentDate, getUserInfo, getUUIDNumberFromLocalStorage } from "../../helpers/utilCommon";
import * as types from "../../redux/roster/types";
import { setMessage } from '../../redux/shared/actions';
import { fetchReportsData, checkFileExistAction, fetchStudentRosterReport } from "../../services/rosterService";
import RenderCheckbox from '../atoms/Checkbox';
import DatePicker from '../atoms/DatePicker';
import ExportExcel from '../atoms/ExportExcel';

export class RosterReports extends Component {
    constructor(props) {
        super(props)
        this.state = {
            meetingDate: '',
            isDateError: null,
            isLoadingExcelRoster: false,
            isLoadingWordRoster: false,
            isLoadingVisitorAndRoster: false,
            isLoadingVisitorOnly: false,
            isLoadingMembershipReport: null,
            membershipReportFlags: {
                NewMember: false,
                Affiliates: false,
                MembersChangingEmployment: false,
                CommitteeBalance: false,
                MembersRemovedFromRoster: false,
                InactivityReport: false,
                ProducerWaitList: false,
                NewStudentMember: false,
                StudentRemovedFromRoster: false,
                HonoraryMemberList: false,
                StartDate: new Date(moment().subtract(6, 'months').format(uiDateFormat)),
                EndDate: new Date(moment().format(uiDateFormat)),
                SelectAll: false,
                ReportCheckedCount: 0
            },
            isLoadingStudentExcelRoster: false,
        }
    }

    componentDidMount() {
        addDocXOnRequest();
    }

    /**
     * Hnadle Membership report options.
     * @key {string} option name
     * @value {string} option value
     */
    handleReportFlagChange = (key, value = null) => {
        let currentReportFlags = { ...this.state.membershipReportFlags }
        const {studentcommitteemanagement} = this.props.featureFlag;
        if (key !== 'StartDate' && key !== 'EndDate') {
            value = !currentReportFlags[key];
            if (key === 'SelectAll') {
                if (value) { // If 'Select All' is checked
                    currentReportFlags.NewMember = true;
                    currentReportFlags.Affiliates = true;
                    currentReportFlags.MembersChangingEmployment = true;
                    currentReportFlags.CommitteeBalance = true;
                    currentReportFlags.MembersRemovedFromRoster = true;
                    currentReportFlags.InactivityReport = true;
                    currentReportFlags.ProducerWaitList = true;
                    currentReportFlags.HonoraryMemberList = true;
                    currentReportFlags.ReportCheckedCount = 7;
                    if(studentcommitteemanagement){
                     currentReportFlags.NewStudentMember = true;
                     currentReportFlags.StudentRemovedFromRoster = true;
                    }
                } else { // If 'Select All' is unchecked
                    currentReportFlags.NewMember = false;
                    currentReportFlags.Affiliates = false;
                    currentReportFlags.MembersChangingEmployment = false;
                    currentReportFlags.CommitteeBalance = false;
                    currentReportFlags.MembersRemovedFromRoster = false;
                    currentReportFlags.ProducerWaitList = false;
                    currentReportFlags.HonoraryMemberList = false;
                    currentReportFlags.InactivityReport = false;
                    currentReportFlags.NewStudentMember = false;
                     currentReportFlags.StudentRemovedFromRoster = false;
                    currentReportFlags.ReportCheckedCount = 0;
                }
            } else if (!value) { // If other checkbox is unchecked
                currentReportFlags.SelectAll = false;
                currentReportFlags.ReportCheckedCount -= 1;
            } else {
                currentReportFlags.ReportCheckedCount += 1;
            }
        }
        currentReportFlags[key] = value;
        // Check if all checkbox is checked then check 'Select All'
        if (currentReportFlags.NewMember
            && currentReportFlags.Affiliates
            && currentReportFlags.MembersChangingEmployment
            && currentReportFlags.CommitteeBalance
            && currentReportFlags.MembersRemovedFromRoster
            && currentReportFlags.ProducerWaitList
            && currentReportFlags.HonoraryMemberList
            && currentReportFlags.InactivityReport && ((studentcommitteemanagement && currentReportFlags.NewStudentMember
            && currentReportFlags.StudentRemovedFromRoster ) ||(!studentcommitteemanagement))) {
            currentReportFlags.SelectAll = true;
        }
        this.setState({
            membershipReportFlags: { ...currentReportFlags }
        })
    }

    downloadRosterStudentExcel = () => {
        const tabName = this.props.selectedCommitteeWithPermission.designation + " Student Member List";
        const userObj = getUserInfo();
        let inputDTO = {
            "CommitteeCode": this.props.selectedCommitteeWithPermission.designation,
            "CommitteeId": this.props.selectedCommittee || null,
            "ExcelRoster": true,
            "MemberAccountNumber": userObj.memberAccountNumber,
            "Studentcommitteemanagement": this.props.featureFlag?.studentcommitteemanagement,
            "isStudentRoster": true,
            "fileName": tabName + " " + getCurrentDate("MM.DD.YYYY"),
            "tabName": tabName
        }
        this.setState({ isLoadingStudentExcelRoster: true });
        fetchStudentRosterReport(this.props.selectedCommittee, (err, response) => {
            if (err) {
                this.props.setMessage(true, '402', '');
                this.setState({ isLoadingStudentExcelRoster: false });
            } else {
                let excelData = generateStudentRosterExcelData(response);
                this.props.setExcelData({ ...inputDTO, excelData });
                this.setState({ isLoadingStudentExcelRoster: false });
            }
        });
    }

    downloadExcelReport = () => {
        const userObj = getUserInfo();
        let inputDTO = {
            "CommitteeCode": this.props.selectedCommitteeWithPermission.designation,
            "CommitteeId": this.props.selectedCommittee || null,
            "ExcelRoster": true,
            "MemberAccountNumber": userObj.memberAccountNumber,
            "Studentcommitteemanagement": this.props.featureFlag?.studentcommitteemanagement
        }
        this.setState({ isLoadingExcelRoster: true });
        fetchReportsData(inputDTO, (err, response) => {
            if (err) {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                this.props.setMessage(true, '402', uuidNumber);
                this.setState({ isLoadingExcelRoster: false });
            } else {
                let excelData = this.generateExcelData(response.ExcelRoster, inputDTO.CommitteeCode);
                this.props.setExcelData({ ...inputDTO, excelData });
                this.setState({ isLoadingExcelRoster: false });
            }
        });
    }

    generateExcelData = (responseData, CommitteeCode) => {
        let obj = {};
        const isSubCommittee = CommitteeCode ? CommitteeCode.indexOf(".") > -1 : false;
        obj.columns = excelColumns(!isSubCommittee);
        let rows = [];
        for (let i = 0; i < responseData.length; i++) {
            let row = [
                { value: CommitteeCode || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].OfficerTitle || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].MiddleName ? `${responseData[i].LastName}, ${responseData[i].FirstName} ${responseData[i].MiddleName}` : `${responseData[i].LastName}, ${responseData[i].FirstName}` || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].Organization || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: `${moment(responseData[i].JoinDate).format('MM/DD/YYYY')}` || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].Classification || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: `${moment(responseData[i].ClassificationAssignedDate).format('MM/DD/YYYY')}` || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].IsOfficialVote ? 'Yes' : 'No', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].NoVoteReason || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: `${moment(responseData[i].VoteAssignedDate).format('MM/DD/YYYY')}` || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].Address || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].City || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].State || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].PostalCode || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].Country || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].Phone || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].Fax || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].Email || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
                { value: responseData[i].MemberType || '', style: { font: { sz: "10", bold: false, name: "Arial" } } },
            ];
            if (!isSubCommittee) {
                row.push( { value: responseData[i].RosterNotes || '', style: { font: { sz: "10", bold: false, name: "Arial" } } });
            }
            rows.push(row);
        }
        obj.data = rows;
        return [obj];
    }

    downloadWordReport = () => {
        const userObj = getUserInfo();
        const { selectedCommittee } = this.props;
        let inputDTO = {
            "CommitteeId": selectedCommittee,
            "WordRoster": true,
            "MemberAccountNumber": userObj.memberAccountNumber,
            "Studentcommitteemanagement": this.props.featureFlag?.studentcommitteemanagement
        }
        this.setState({ isLoadingWordRoster: true });
        fetchReportsData(inputDTO, (err, response) => {
            if (err) {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                this.props.setMessage(true, '402', uuidNumber);
                this.setState({ isLoadingWordRoster: false });
            } else {
                const payload = response && response.WordRoster[0];
                let doc = new window.RosterMaintenanceScript(payload);
                doc.exportDoc(() => {
                    this.setState({ isLoadingWordRoster: false });
                });
            }
        });
    }

    sendDateError(errorCode, field) {
        this.setState({ isDateError: field }, () => {
            this.props.setMessage(true, errorCode);
        })
    }

    handleVisitorAndRoster = () => {
        const { meetingDate } = this.state;
        const meetingDateMoment = moment(meetingDate);
        if (meetingDate === '' || !meetingDateMoment.isValid()) {
            this.sendDateError(10010, "meeting-date");
            return
        }
        this.setState({ isLoadingVisitorAndRoster: true, isDateError: null });

        const { selectedCommittee } = this.props;
        const input = {
            "CommitteeId": selectedCommittee,
            "MeetingDate": moment(meetingDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            "RosterAndVisitor": "true",
            "Studentcommitteemanagement": this.props.featureFlag?.studentcommitteemanagement
        };
        fetchReportsData(input, (err, res) => {
            if (err) {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                this.props.setMessage(true, '402', uuidNumber);
                this.setState({ isLoadingVisitorAndRoster: null });
            } else {
                const RosterAndVisitor = res && res.RosterAndVisitor;
                if (!(RosterAndVisitor && RosterAndVisitor[0])) {
                    this.props.setMessage(true, 4001);
                    this.setState({ isLoadingVisitorAndRoster: null });
                    return;
                }
                const payload = {
                    meetingDate,
                    rosterAndVisitor: RosterAndVisitor[0]
                };
                let rosterAndVisitorDocx = new window.RosterAndVisitorScript(payload);
                rosterAndVisitorDocx.exportDoc(() => {
                    this.setState({ isLoadingVisitorAndRoster: null });
                });
            }
        });
    }

    handleVisitorOnly = () => {
        const { meetingDate } = this.state;
        const meetingDateMoment = moment(meetingDate);

        if (meetingDate === '' || meetingDate === null) {
            this.sendDateError(10010, "meeting-date");
            return;
        } else if (!meetingDateMoment.isValid()) {
            this.sendDateError(1001, "meeting-date");
            return;
        }

        this.setState({ isLoadingVisitorOnly: true, isDateError: null });

        const { selectedCommittee } = this.props;
        const input = {
            "CommitteeId": selectedCommittee,
            "MeetingDate": moment(meetingDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            "VisitorOnly": "true",
            "Studentcommitteemanagement": this.props.featureFlag?.studentcommitteemanagement
        };

        fetchReportsData(input, (err, res) => {
            if (err) {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                this.props.setMessage(true, '402', uuidNumber);
                this.setState({ isLoadingVisitorOnly: false });
            } else {
                const VisitorOnly = res && res.VisitorOnly;
                if (!(VisitorOnly && VisitorOnly[0])) {
                    this.props.setMessage(true, 4001);
                    this.setState({ isLoadingVisitorOnly: false });
                    return;
                }
                const payload = {
                    meetingDate,
                    visitorData: VisitorOnly[0]
                };
                let visitorDocx = new window.VisitorOnlyScript(payload);
                visitorDocx.exportDoc(() => {
                    this.setState({ isLoadingVisitorOnly: null });
                });
            }
        });
    }

    /**
     * Generate and download Membership report in word format.     
     */
    downloadMembershipReport = () => {
        if (!this.validateMembershipReportOptions()) {
            return;
        }
        let { membershipReportFlags } = this.state;
        let { StartDate, EndDate } = membershipReportFlags;
        this.setState({ isLoadingMembershipReport: true, isDateError: null });
        const { selectedCommittee, selectedCommitteeWithPermission, committeeSummary, featureFlag } = this.props;
        const committeeDesignation = selectedCommitteeWithPermission.designation;
        const input = { ...membershipReportFlags, "CommitteeId": selectedCommittee,
            "Studentcommitteemanagement": this.props.featureFlag?.studentcommitteemanagement
         };
        let isMerging = membershipReportFlags.ReportCheckedCount > 1 ? true : false;

        if (input.InactivityReport) {
            let inActivityCommittee = committeeDesignation;
            let firstDotIndex = committeeDesignation.indexOf(".");
            if (firstDotIndex !== -1) {
                let mainCommitee = committeeDesignation.substr(0, firstDotIndex);
                let subCommitee = committeeDesignation.substr(firstDotIndex + 1);
                if (mainCommitee.length < 3) {
                    let charDiff = 3 - mainCommitee.length;
                    let multiplier = Math.pow(10, charDiff);
                    let addZeros = multiplier.toString().substr(1);
                    mainCommitee += addZeros;
                }
                if (subCommitee.length < 4) {
                    let charDiff = 4 - subCommitee.length;
                    let multiplier = Math.pow(10, charDiff);
                    let addZeros = multiplier.toString().substr(1);
                    subCommitee += addZeros;
                }
                inActivityCommittee = mainCommitee + subCommitee;
            } else {
                let mainCommitee = committeeDesignation;
                if (mainCommitee.length < 3) {
                    let charDiff = 3 - mainCommitee.length;
                    let multiplier = Math.pow(10, charDiff);
                    let addZeros = multiplier.toString().substr(1);
                    mainCommitee += addZeros;
                }
                inActivityCommittee = mainCommitee;
            }
            const url = inActivityURL.replace("#COMMITTEE-DESIGNATION#", inActivityCommittee);
            checkFileExistAction({fileUrl: url}, (err, isFileExist) => {
                if(isFileExist){
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('target', '_blank');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            });
            
            if (!isMerging) {
                this.setState({ isLoadingMembershipReport: null });
                return;
            }
        }
        delete input.InactivityReport;
        input.StartDate = StartDate ? moment(StartDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null;
        input.EndDate = EndDate ? moment(EndDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null;

        fetchReportsData(input, (err, response) => {
            if (err) {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                this.props.setMessage(true, '402', uuidNumber);
                this.setState({ isLoadingMembershipReport: null });
            } else {
                let reportDateRange = moment(StartDate).format(uiDateFormat) + " - " + moment(EndDate).format(uiDateFormat);
                const payload = {
                    reportDateRange: reportDateRange,
                    committeeDesignation: this.props.selectedCommitteeWithPermission.designation,
                    response: response,
                    membershipReportFlags: membershipReportFlags,
                    enableStudent: (featureFlag?.studentcommitteemanagement && committeeSummary?.isstudentenabled),
                    enableStudentCommitteemanagement: (featureFlag?.studentcommitteemanagement),
                };
                let doc = new window.MembershipReportScript(payload);
                doc.exportDoc(() => {
                    this.setState({ isLoadingMembershipReport: null });
                });
            }
        });
        return;
    }

    validateMembershipReportOptions = () => {
        let { StartDate,
            EndDate,
            NewMember,
            MembersChangingEmployment,
            MembersRemovedFromRoster,
            Affiliates,
            CommitteeBalance,
            InactivityReport,
            ProducerWaitList,
            NewStudentMember,
            StudentRemovedFromRoster,
            HonoraryMemberList
        } = this.state.membershipReportFlags;
        let {studentcommitteemanagement  }= this.props.featureFlag;
        let {isstudentenabled} = this.props.committeeSummary;
        let errorCode = '';
        let errorControll = '';
        if (((!studentcommitteemanagement || !isstudentenabled) && !(NewMember || Affiliates || CommitteeBalance || InactivityReport || MembersChangingEmployment
            || MembersRemovedFromRoster || ProducerWaitList || HonoraryMemberList))) {
            errorCode = 10014;
            errorControll = "";
        } else if ((studentcommitteemanagement && isstudentenabled && !(NewMember || Affiliates || CommitteeBalance || InactivityReport || MembersChangingEmployment
            || MembersRemovedFromRoster || ProducerWaitList || HonoraryMemberList || NewStudentMember
            || StudentRemovedFromRoster))){
               errorCode = 10014;
               errorControll = ""; 
            }

        if (((studentcommitteemanagement && isstudentenabled && (NewMember || MembersChangingEmployment || MembersRemovedFromRoster || NewStudentMember
            || StudentRemovedFromRoster))||
            (((!studentcommitteemanagement|| !isstudentenabled) &&( NewMember || MembersChangingEmployment || MembersRemovedFromRoster))))
            && ((StartDate === '' || StartDate === null) || (EndDate === '' || EndDate === null))) {
            errorCode = 10013;
            errorControll = "both";

        }

        if (StartDate !== '' && StartDate !== null && EndDate !== '' && EndDate !== null) {
            const currentDate = moment();
            const fromDate = moment(StartDate);
            let toDate = moment(EndDate);

            if (!fromDate.isValid()) {
                errorCode = 1001;
                errorControll = "start-date";
            }

            if (!toDate.isValid()) {
                errorCode = 1001;
                errorControll = "end-date";
            }

            if (fromDate.isAfter(toDate)) {
                errorCode = 10011;
                errorControll = "both";
            }

            if (fromDate.isAfter(currentDate)) {
                errorCode = 10012;
                errorControll = "start-date";
            }
        }

        if (errorCode) {
            this.sendDateError(errorCode, errorControll);

            return false;
        }

        return true;
    }

    render() {
        const {
            meetingDate,
            isDateError,
            isLoadingExcelRoster,
            isLoadingWordRoster,
            isLoadingVisitorAndRoster,
            isLoadingVisitorOnly,
            isLoadingMembershipReport,
            membershipReportFlags,
            isLoadingStudentExcelRoster
        } = this.state;

        const { rosterReport, committeeSummary, featureFlag } = this.props;
        const reportIdArray = rosterReport.map((report) => report.reportid);
        return (
            <React.Fragment>
                <div data-testid="RosterReportCmp">
                    {reportIdArray.indexOf(1) === -1 ? null : (
                        <section className="roster-report-section mt20">
                            <div className="report-header clearfix">
                                <span className="mobile-block display-inline-block mt10">Roster</span>
                                <div className="display-inline-block floatRight report-mobile">
                                    <Button data-testid="downloadExcelReportBtn" variant="secondary" className="btn astm-btn btn-secondary mr10" disabled={isLoadingExcelRoster} onClick={this.downloadExcelReport}>
                                        {isLoadingExcelRoster ? ' Loading...' : ' Excel'}
                                    </Button>

                                    <Button data-testid="downloadWordReportBtn" variant="secondary" className="btn astm-btn btn-secondary" disabled={isLoadingWordRoster} onClick={this.downloadWordReport}>
                                        {isLoadingWordRoster ? ' Loading...' : ' Word'}
                                    </Button>
                                </div>
                            </div>
                        </section>
                    )}
                    {committeeSummary?.isstudentenabled && featureFlag?.studentcommitteemanagement &&
                        <section className="roster-report-section">
                            <div className="report-header clearfix">
                                <span className="mobile-block display-inline-block mt10">Student Member List</span>
                                <div className="display-inline-block floatRight report-mobile">
                                    <Button data-testid="downloadStudentExcelReportBtn" variant="secondary" className="btn astm-btn btn-secondary" disabled={isLoadingStudentExcelRoster} onClick={this.downloadRosterStudentExcel}>
                                        {isLoadingStudentExcelRoster ? ' Loading...' : ' Excel'}
                                    </Button>
                                </div>
                            </div>
                        </section>
                    }
                    {reportIdArray.indexOf(3) === -1 ? null : (
                        <section className="roster-report-section">
                            <div className="report-header clearfix">
                                <span className="report-header-meeting">Meeting Attendance List</span>
                                <div className="meeting-list-mview mt20 clearfix">
                                    <div className="date-section floatLeft">
                                        <Form>
                                            <DatePicker
                                                className="meeting-date-input"
                                                name='date'
                                                id='date'
                                                value={meetingDate}
                                                error={isDateError && isDateError === 'meeting-date' ? true : false}
                                                label='Meeting Date:'
                                                placeholder="MM/DD/YYYY"
                                                changeHandler={(value) => { this.setState({ meetingDate: value }); }}
                                            />
                                        </Form>
                                    </div>
                                    <div className="btnWrap floatRight">
                                        <Button data-testid="handleVisitorAndRosterBtn" variant="secondary" className="btn astm-btn btn-secondary mr10" disabled={isLoadingVisitorAndRoster} onClick={this.handleVisitorAndRoster}>
                                            {isLoadingVisitorAndRoster ? 'Loading...' : 'Roster & Visitor'}
                                        </Button>

                                        <Button data-testid="handleVisitorOnlyBtn" variant="secondary" className="btn astm-btn btn-secondary" disabled={isLoadingVisitorOnly} onClick={this.handleVisitorOnly}>
                                            {isLoadingVisitorOnly ? 'Loading...' : 'Visitor Only'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                    {reportIdArray.indexOf(5) === -1 ? null : (
                        <section className="roster-report-section">
                            <div className="report-header"><span>Membership Report</span></div>
                            <div className="report-content">
                                <Form>
                                    <div className="date-section mb20">
                                        <Row>
                                            <Col>
                                                <DatePicker
                                                    name='membershipStartDate'
                                                    id='membershipStartDate'
                                                    label="Start Date"
                                                    error={isDateError && (isDateError === 'start-date' || isDateError === 'both') ? true : false}
                                                    changeHandler={(value) => { this.handleReportFlagChange('StartDate', value); }}
                                                    value={membershipReportFlags.StartDate}
                                                    placeholder="MM/DD/YYYY"
                                                    blockLabel={true}
                                                />
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    name='membershipEndDate'
                                                    id='membershipEndDate'
                                                    label="End Date"
                                                    error={isDateError && (isDateError === 'end-date' || isDateError === 'both') ? true : false}
                                                    changeHandler={(value) => { this.handleReportFlagChange('EndDate', value); }}
                                                    value={membershipReportFlags.EndDate}
                                                    placeholder="MM/DD/YYYY"
                                                    blockLabel={true}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="report-checkbox-section">

                                        {(!featureFlag?.studentcommitteemanagement || !committeeSummary?.isstudentenabled) && <>
                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='newMembers'
                                                            label='New Members'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.NewMember}
                                                            onChange={() => { this.handleReportFlagChange('NewMember') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='currentAffiliateMembers'
                                                            label='Current Affiliate Members'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.Affiliates}
                                                            onChange={() => { this.handleReportFlagChange('Affiliates') }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='membersChangingEmployment'
                                                            label='Members Changing Employment'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.MembersChangingEmployment}
                                                            onChange={() => { this.handleReportFlagChange('MembersChangingEmployment') }}
                                                        />
                                                    </Col>

                                                    <Col>
                                                        <RenderCheckbox
                                                            name='committeeBalance'
                                                            label='Committee Balance'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.CommitteeBalance}
                                                            onChange={() => { this.handleReportFlagChange('CommitteeBalance') }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='membersRemovedfromRoster'
                                                            label='Members Removed from Roster'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.MembersRemovedFromRoster}
                                                            onChange={() => { this.handleReportFlagChange('MembersRemovedFromRoster') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='inactivityReport'
                                                            label='Inactivity Report'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.InactivityReport}
                                                            onChange={() => { this.handleReportFlagChange('InactivityReport') }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='currentProducerWaitList'
                                                            label='Current Producer Wait List'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.ProducerWaitList}
                                                            onChange={() => { this.handleReportFlagChange('ProducerWaitList') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='honoraryMembersList'
                                                            label='Honorary Members List'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.HonoraryMemberList}
                                                            onChange={() => { this.handleReportFlagChange('HonoraryMemberList') }}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Form.Group>
                                        </>}
                                        {(featureFlag?.studentcommitteemanagement && committeeSummary?.isstudentenabled) && <>
                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='newMembers'
                                                            label='New Members'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.NewMember}
                                                            onChange={() => { this.handleReportFlagChange('NewMember') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='newStudentMembers'
                                                            label='New Student Members'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.NewStudentMember}
                                                            onChange={() => { this.handleReportFlagChange('NewStudentMember') }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='membersRemovedfromRoster'
                                                            label='Members Removed from Roster'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.MembersRemovedFromRoster}
                                                            onChange={() => { this.handleReportFlagChange('MembersRemovedFromRoster') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='studentsRemovedfromRoster'
                                                            label='Students Removed from Roster'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.StudentRemovedFromRoster}
                                                            onChange={() => { this.handleReportFlagChange('StudentRemovedFromRoster') }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='currentAffiliateMembers'
                                                            label='Current Affiliate Members'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.Affiliates}
                                                            onChange={() => { this.handleReportFlagChange('Affiliates') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='membersChangingEmployment'
                                                            label='Members Changing Employment'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.MembersChangingEmployment}
                                                            onChange={() => { this.handleReportFlagChange('MembersChangingEmployment') }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='committeeBalance'
                                                            label='Committee Balance'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.CommitteeBalance}
                                                            onChange={() => { this.handleReportFlagChange('CommitteeBalance') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='inactivityReport'
                                                            label='Inactivity Report'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.InactivityReport}
                                                            onChange={() => { this.handleReportFlagChange('InactivityReport') }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group widths='equal'>
                                                <Row>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='currentProducerWaitList'
                                                            label='Current Producer Wait List'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.ProducerWaitList}
                                                            onChange={() => { this.handleReportFlagChange('ProducerWaitList') }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RenderCheckbox
                                                            name='honoraryMembersList'
                                                            label='Honorary Members List'
                                                            checked={membershipReportFlags.SelectAll || membershipReportFlags.HonoraryMemberList}
                                                            onChange={() => { this.handleReportFlagChange('HonoraryMemberList') }}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Form.Group>
                                        </>}
                                        <Form.Group widths='equal'>
                                            <Row>
                                                <Col>
                                                    <RenderCheckbox
                                                        name='selectAll'
                                                        label='Select All'
                                                        checked={membershipReportFlags.SelectAll}
                                                        onChange={() => { this.handleReportFlagChange('SelectAll') }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Button variant="secondary" data-testid="downloadMembershipReportBtn" className="btn astm-btn btn-secondary " disabled={isLoadingMembershipReport} onClick={this.downloadMembershipReport}>
                                            {isLoadingMembershipReport ? 'Loading...' : 'Download'}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </section>
                    )
                    }
                </div>
                {
                    this.props.downloadExcelFlag && !this.props.rosterReportsExcelData?.isStudentRoster
                    && <ExportExcel fileName={`${this.props.rosterReportsExcelData.CommitteeCode + ' Roster'}`} tabName={`${this.props.rosterReportsExcelData.CommitteeCode + ' Roster'}`} multiDataSet={this.props.rosterReportsExcelData.excelData} />
                }
                {
                    this.props.downloadExcelFlag && this.props.rosterReportsExcelData.isStudentRoster
                    && <ExportExcel fileName={`${this.props.rosterReportsExcelData.fileName}`} tabName={`${this.props.rosterReportsExcelData.tabName}`} multiDataSet={this.props.rosterReportsExcelData.excelData} />
                }
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => {
    let { selectedCommittee, rosterReportsExcelData, downloadExcelFlag, committeeSummary } = state.roster;
    return ({
        selectedCommittee: selectedCommittee || null,
        rosterReportsExcelData: rosterReportsExcelData || {},
        downloadExcelFlag: downloadExcelFlag || false,
        committeeSummary
    });
};

const mapDispatchToProps = dispatch => ({
    setExcelData: rosterReportsExcelData => {
        dispatch({ type: types.SET_ROSTER_EXCEL_DATA, rosterReportsExcelData, downloadExcelFlag: true });
    },
    setMessage: (flag, code) => dispatch(setMessage(flag, code))
});

export default connect(mapStateToProps, mapDispatchToProps)(RosterReports)
