import { clone, map } from "lodash";
import React, { Component } from "react";
import { Accordion, Card } from 'react-bootstrap';
// reponsive
import MediaQuery from "react-responsive";
import { maxWidthValue, minWidthValue } from "../../../helpers/constant.js";
import CustomLoader from "../../atoms/CustomLoader";
import StyleSheetGenerator from "../../atoms/StyleSheetGenerator";
import PendingAndWaitList from "../../organisms/PendingAndWaitList";
import RosterAndReportsTab from "../../organisms/RosterAndReportsTab";
import RosterHeader from "../../organisms/RosterHeader";
import RosterReports from "../../organisms/RosterReports";
import Summary from "../../organisms/Summary";


class Roster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isStick: false,
      lastScrolled: 0,
      activeIndex: ''
    };
  }

  componentDidMount() {
    this.props.updatePendingApplication(0);
    this.clearFiltersAndSorting();
    let root = document.getElementById("root");
    if (root) {
      root.addEventListener("scroll", this.handleScroll.bind(this));
    }
  }

  handleScroll() {
    let applicationReport = document.querySelector(".applicationReport");
    if (applicationReport) {
      let { lastScrolled, isStick } = this.state;
      let top = applicationReport.getBoundingClientRect().top;
      if (!isStick && top < 0) {
        let lastScrolled = document.querySelector("#root").scrollTop;
        this.setState({ isStick: true, lastScrolled });
      } else if (isStick) {
        let currentScroll = document.querySelector("#root").scrollTop;
        if (currentScroll < lastScrolled) {
          this.setState({ isStick: false, lastScrolled: currentScroll });
        }
      }
    }
  }

  committeeHandler = value => {
    const { featureFlag } = this.props;
    let isExcludeStudent = featureFlag?.studentcommitteemanagement;
    this.props.commonCommitteeInfo(value, isExcludeStudent);
    this.clearFiltersAndSorting();
  };

  clearFiltersAndSorting = () => {
    this.props.clearRosterSortOrder();
    this.props.clearPendingSortOrder();
    this.props.clearWaitingSortOrder();
    this.props.clearAllFilter();
    this.props.resetAdvanceSearch();
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleClick = (eventKey) => {
    this.setState({ activeIndex: eventKey });
  };

  render() {
    const { activeIndex } = this.state;
    const {
      userPermission,
      updatedPendingApplication,
      isCommitteeListLoading,
      committeeList,
      selectedCommittee,
      selectedCommitteeWithPermission,
      isSummaryLoading,
      committeeSummary,
      isMembersLoading,
      memberList,
      committeeMemberList,
      committeeClassificationList,
      voteList,
      rosterReport
    } = this.props;

    let committeeOptions = map(committeeList, committeeObj => ({
      key: committeeObj.committeeid,
      value: committeeObj.committeeid,
      text: committeeObj.code
    }));

    let dynamicStyleSheet = "";
    let colorArray = clone(committeeClassificationList);
    if (colorArray && colorArray.length) {
      colorArray.push({
        id: 0,
        name: "default",
        colorcode: "#c8c8c8"
      });
      map(colorArray, colorObj => {
        let color = colorObj.colorcode.substr(1);
        dynamicStyleSheet += `
                .custom-select-${color} select.form-control {
                  border: 1px solid #${color} !important;
                }
                .custom-select-${color}::before { 
                  display: inline-block;
                    content: '';
                    -webkit-border-radius: 0.375rem;
                    border-radius: 0.375rem;
                    height: 6px;
                    width: 6px;
                    margin: 0px 5px 1px 0px;
                    background-color: #${color};
                    position: absolute;
                    left: 7px;
                    top: 12px;
                  }
                  .custom-chart-tooltip-${color} {
                    border-color: #${color} !important;
                  }
                  .custom-chart-tooltip-${color}::before {
                    border-top-color: #${color} !important;
                  }`;
      });
    }
    let totalpendingapplications = userPermission.totalpendingapplications + updatedPendingApplication;
    if (totalpendingapplications < 0){
      totalpendingapplications = 0
    }
    return (
      <div data-testid="RosterComponent" id="roster-app" className="container astm-roster-container">

        <div className="roasterWrap scrollData clearfix">
          <RosterHeader
            selectedCommitteeWithPermission={selectedCommitteeWithPermission}
            totalpendingapplications={totalpendingapplications}
            isCommitteeListLoading={isCommitteeListLoading}
            isMembersLoading={isMembersLoading}
            selectedCommittee={selectedCommittee}
            committeeOptions={committeeOptions}
            onChangeCommitteeHandler={this.committeeHandler}
          />
          <MediaQuery minDeviceWidth={minWidthValue.tabletOrDesktop}>
            <div className="leftPane">
              <section className="summaryReport">
                <Summary
                  isSummaryLoading={isSummaryLoading}
                  committeeSummary={committeeSummary}
                />
              </section>
              <section
                className={
                  "applicationReport" +
                  (this.state.isStick ? " sticky-list" : "")
                }
              >
                <PendingAndWaitList
                  isMembersLoading={isMembersLoading}
                  memberList={memberList}
                  committeeMemberList={committeeMemberList}
                  selectedCommitteeWithPermission={
                    selectedCommitteeWithPermission
                  }
                  committeeClassificationList={committeeClassificationList}
                  voteList={voteList}
                  {...this.props}
                />
              </section>
            </div>
            <div className="rightPane">
              {isMembersLoading ? (
                <div className="member-loader-view rightPane-loader txtCenter vMiddle">
                  <div>
                    <CustomLoader isVisible={false} />
                  </div>
                </div>
              ) : (
                <RosterAndReportsTab
                  memberList={memberList}
                  committeeMemberList={committeeMemberList}
                  selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                  committeeClassificationList={committeeClassificationList}
                  voteList={voteList}
                  rosterReport={rosterReport}
                  featureFlag={this.props.featureFlag}
                  committeeSummary={committeeSummary}
                />
              )}
            </div>
          </MediaQuery>

          <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
            <div className="mobile-tab-section">
              <Accordion className="astm-accordion astm-accordion--full-width" activeKey={activeIndex} onSelect={this.handleClick}>
                <Card className="astm-card">
                  <Card.Header>
                    {/* Summary Section */}
                    <Accordion.Toggle as={Card} eventKey={'committeeSummary'}>
                      <h2 className="mb-0">
                        <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" aria-expanded="true" >
                          Committee Summary
                        </button>
                      </h2>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={'committeeSummary'}>
                    <Card.Body>
                      <section className="summaryReport">
                        <Summary
                          isSummaryLoading={isSummaryLoading}
                          committeeSummary={committeeSummary}
                        />
                      </section>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                {/* Pending Applications Section && Producer Wait List Section*/}
                <PendingAndWaitList
                  isMembersLoading={isMembersLoading}
                  memberList={memberList}
                  committeeMemberList={committeeMemberList}
                  selectedCommitteeWithPermission={
                    selectedCommitteeWithPermission
                  }
                  committeeClassificationList={committeeClassificationList}
                  voteList={voteList}
                  {...this.props}
                />

                {/* Roster Section */}
                <Card className="astm-card">
                  <Card.Header>
                    <Accordion.Toggle as={Card} eventKey={'roster'}>
                      <h2 className="mb-0">
                        <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" aria-expanded="true" >
                          Roster
                        </button>
                      </h2>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={'roster'}>
                    <Card.Body>
                      {isMembersLoading ? (
                        <div
                          className="member-loader-view txtCenter vMiddle"
                          centered
                          columns={1}
                        >
                          <div>
                            <CustomLoader isVisible={false} />
                          </div>
                        </div>
                      ) : (
                        <RosterAndReportsTab
                          memberList={memberList}
                          committeeMemberList={committeeMemberList}
                          selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                          committeeClassificationList={committeeClassificationList}
                          voteList={voteList}
                        />
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                {rosterReport && rosterReport.length ? (
                  <Card className="astm-card">
                    <Card.Header>
                      <Accordion.Toggle as={Card} eventKey={'rosterReport'}>
                        <h2 className="mb-0">
                          <button className="btn astm-btn btn-link" type="button" data-toggle="collapse" aria-expanded="true" >
                            Roster Reports
                          </button>
                        </h2>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={'rosterReport'}>
                      <Card.Body>
                        {isMembersLoading ? (
                          <div className="member-loader-view txtCenter vMiddle" centered columns={1}>
                            <div>
                              <CustomLoader isVisible={false} />
                            </div>
                          </div>
                        ) : (
                          <section>
                            <RosterReports
                              selectedCommitteeWithPermission={selectedCommitteeWithPermission}
                              rosterReport={rosterReport}
                            />
                          </section>
                        )}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ) : null}
              </Accordion>
            </div>
          </MediaQuery>
        </div>
        <StyleSheetGenerator stylesheet={dynamicStyleSheet} />
      </div >
    );
  }
}
export default Roster;
