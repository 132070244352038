import { isEmpty, get } from 'lodash';
import { buildURL, callAPI, Urls } from "../helpers/apiUrls";
import { getUserInfo, getUUIDNumberFromLocalStorage } from '../helpers/utilCommon';
import { updateClassAndVoteLoader } from '../redux/editLoader/actions';
import * as actions from '../redux/roster/actions';
import { setMessage } from '../redux/shared/actions';

export const fetchCommittee = (committeeId, isExcludeStudent) => {
    return dispatch => {
        dispatch(actions.setSelectedCommittee(committeeId));
        dispatch(actions.showSummaryLoader());


        const url = `${buildURL(Urls.CommitteeSummaryByCommitteeId, null, { committeeId, isExcludeStudent })}`;

        callAPI(url, 'get', null, true, true, (response) => {
            if (response && response.responsestatus) {
                const { responsedata } = response;
                dispatch(actions.setCommitteeSummary(responsedata[0]));
            } else {
                dispatch(actions.hideSummaryLoader());
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                dispatch(setMessage(true, '402', uuidNumber));
            }
        });
    }
}

export const classificationUpdate = value => {
    return dispatch => {
        dispatch(updateClassAndVoteLoader(value.memberid, true, value.committeeClassificationId, 'class'));

        const { memberid: memberId, isExcludeStudent } = value;

        const userObj = getUserInfo();

        let postData = {
            committeeId: value.committeeid,
            committeeClassificationId: value.committeeClassificationId,
            modifiedBy: userObj.memberid
        };
        const url = `${buildURL(Urls.UpdateClass, null, { memberId })}`;

        callAPI(url, 'patch', postData, true, true, (response) => {
            if (response && response.responsestatus) {
                dispatch(fetchCommittee(value.committeeid, isExcludeStudent));
                dispatch(actions.setMemberList(value.memberList));
                dispatch(updateClassAndVoteLoader(value.memberid, false, null, null));
                dispatch(setMessage(true, '2001'));
            } else {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                dispatch(setMessage(true, '402', uuidNumber));
                dispatch(updateClassAndVoteLoader(value.memberid, false, null, null));
            }
        });
    }
}

export const memberOfficialVoteUpdate = value => {
    return dispatch => {
        dispatch(updateClassAndVoteLoader(value.memberid, true, value.voteid, 'vote'));

        const { memberid: memberId, isExcludeStudent } = value;

        const userObj = getUserInfo();

        let postData = {
            committeeId: value.committeeid,
            voteId: value.voteid,
            modifiedBy: userObj.memberid
        };
        const url = `${buildURL(Urls.UpdateVote, null, { memberId })}`;

        callAPI(url, 'patch', postData, true, true, (response) => {
            if (response && response.responsestatus) {
                dispatch(fetchCommittee(value.committeeid, isExcludeStudent));
                dispatch(actions.setMemberList(value.memberList));
                dispatch(updateClassAndVoteLoader(value.memberid, false, null, null));
                dispatch(actions.updatePendingApplication(value.updatedTotalApplication));
                dispatch(setMessage(true, '0003'));
            } else {
                dispatch(updateClassAndVoteLoader(value.memberid, false, null, null));
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                dispatch(setMessage(true, '402', uuidNumber));
            }
        });
    }
}

export const fetchComitteeMemberDetail = (data, callback) => {
    const { committeeid: committeeId, memberid: memberId } = data;

    let queryData = { committeeId };
    const url = `${buildURL(Urls.MemberDetails, queryData, { memberId })}`;

    callAPI(url, 'get', null, true, true, (response) => {
        if (response && response.responsestatus) {
            const { responsedata } = response;
            callback(null, responsedata);
        } else {
            callback(get(response, 'responsemessage'), null);
        }
    });
}

export const updateRosterNotes = (data, dispatch, callback) => {
    const { committeeId, memberId, rosterNotes } = data;

    const userObj = getUserInfo();

    let postData = {
        committeeId,
        rosterNotes,
        modifiedby: userObj.memberid
    };
    const url = `${buildURL(Urls.UpdateNotes, null, { memberId })}`;

    callAPI(url, 'patch', postData, true, true, (response) => {
        if (response && response.responsestatus) {
            const { responsestatus } = response;
            callback(null, responsestatus);
            dispatch(setMessage(true, '2001'));
        } else {
            callback(get(response, 'responsemessage'), null);
            const uuidNumber = getUUIDNumberFromLocalStorage(url);
            dispatch(setMessage(true, '402', uuidNumber));
        }
    });
}

export const fetchReportsData = (inputDTO, callback) => {
    const userObj = getUserInfo();
    inputDTO.MemberAccountNumber = userObj.accountNumber;

    const url = `${buildURL(Urls.DownloadReport, null)}`;

    callAPI(url, 'post', inputDTO, true, false, (response) => {
        if (response && response.ResponseStatus && !isEmpty(response.ResponseData)) {
            const { ResponseData } = response;
            callback(null, ResponseData[0]);
        } else {
            callback(get(response, 'responsecode'), null);
        }
    });
}

export const checkFileExistAction = (inputDTO, callback) => {
    const url = `${buildURL(Urls.CheckFileExist, null)}`;
    callAPI(url, 'post', inputDTO, true, false, (response) => {
        if (response && response.ResponseStatus && !isEmpty(response.ResponseData)) {
            const { ResponseData } = response;
            callback(null, ResponseData.fileExist);
        } else {
            callback(get(response, 'responsecode'), false);
        }
    });
}

export const fetchStudentRosterReport = (committeeId, callback) => {
    const url = `${buildURL(Urls.StudentRosterReport, '', { committeeId })}`;
    callAPI(url, 'get', null, true, true, (response) => {
        if (response && response.responsestatus) {
            const { responsedata } = response;
            callback(null, responsedata);
        } else {
            callback(get(response, 'responsemessage'), null);
        }
    });
}