// TODO : get domain prefix to set cookies doamin wise.
export const getDoaminPrefix = () => {
    const hostname = window.location.hostname;
    let prefixName = '';
    if (hostname) {
        let envPrefix = hostname.indexOf('-') > -1 ? hostname.split('-') : '';
        if (envPrefix && envPrefix.length > 0) {
            prefixName = `${envPrefix[0].toUpperCase()}`;
            if (prefixName == 'QA') {
                prefixName = 'TEST'
            }
            if (['DEV', 'TEST', 'STAGE'].includes(prefixName)) {
                prefixName = prefixName + '-';
            }
        }
    }
    return prefixName;
}

export const cookieKeysEnum = {
    '_MyASTM': '_MyASTM',
    'ILSCookie': 'MCS-ILS-COOKIE',   
    'ACCOUNT-ID': 'MCS-ACCOUNT-ID',
    'APP_SESSIONID': `${getDoaminPrefix()}MCS-APP-SESSION-ID`,   
    'IS-OKTA-VERIFIED': `${getDoaminPrefix()}IOV`,
    'REDIRECT-URL': `${getDoaminPrefix()}REDIRECT-URL`,   
    'APP-DATA': `${getDoaminPrefix()}APP-DATA`,   
    'USER-INFO-ROSTER': 'USER-INFO-ROSTER',
    'BANNER-DISPLAY': `${getDoaminPrefix()}BANNER-DISPLAY`    
}

export const LOGIN_USER_MODEL = {
    MEMBER_LOGIN: 'MEMBER-LOGIN',
    VERIFY_INFO: 'VERIFY-INFO'
}

export const IDENTITY_DELEGATION_FLAG = 'IdentityDelegation';

export const USER_INFO = 'user-info';

export const pageLinkEnum = [
    {
        label: "All",
        value: 0,
        link: process.env.REACT_APP_CATALOG_SEARCH
    },
    {
        label: "Meetings & Symposia",
        value: 1,
        link: process.env.REACT_APP_MEETING_SYMPOSIA_SEARCH
    },
    {
        label: "Standardization News",
        value: 2,
        link: process.env.REACT_APP_STANDARDIZATION_NEWS_SEARCH
    }, {
        label: "News Releases",
        value: 3,
        link: process.env.REACT_APP_NEWS_RELEASES_SEARCH
    }
]