import MyAccountHeader from '@astm/astm-member-app-my-account-header';
import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import RenderMobileMenu from '../MobileMenu';
import SearchBox from "../../atoms/SearchBox";
import { isEmpty } from 'lodash';
import { memberNickFormat } from '../../../helpers/utilCommon';
import { signOut } from "../../../redux/login/actions";

const TopMenu = (props) => {
  return (
    <div className="topMenuBar">
      <div className="container">
        <Navbar expand="lg">
          <Nav>
            <NavDropdown
              data-testid="nav-top-1"
              title="Products &amp; Services"
              className="pnsLink"
              id="basic-nav-dropdown-1"
            >
              <div data-testid="nav-child-1" className="flex">
                <div className="">
                  <div className="menuTitle">Products</div>
                  <div className="level2Menu">
                    <NavDropdown
                      title={
                        <a
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/standards-and-publications.html"
                          }
                          className="dropdown-item type2"
                        >
                          Standards &amp; Publications{" "}
                          <i className=" fas fa-caret-right astm-icon"></i>
                        </a>
                      }
                    >
                      <div
                        className="innerSubMenu"
                        data-menu="Standards &amp; Publications"
                      >
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/standards-and-publications.html"
                          }
                        >
                          All Standards and Publications
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/standards-and-publications/standards.html"
                          }
                        >
                          Standards Products
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/standards-and-publications/symposia-papers.html"
                          }
                        >
                          Symposia Papers &amp; STPs
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/standards-and-publications/mnl.html"
                          }
                        >
                          Manuals, Monographs, &amp; Data Series
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/standards-and-publications/technical-reports.html"
                          }
                        >
                          Technical Reports
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/standards-and-publications/journals.html"
                          }
                        >
                          Journals
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/reading-room.html"
                          }
                        >
                          Reading Room
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/digital-library.html"
                          }
                        >
                          Authors
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                  </div>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/products-services/bos.html"
                    }
                  >
                    Book of Standards
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/products-services/reading-room.html"
                    }
                  >
                    Reading Room
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/products-services/tracker-services.html"
                    }
                  >
                    Tracker Services
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/products-services/catalogs.html"
                    }
                  >
                    Catalogs
                  </NavDropdown.Item>
                  <div className="menuTitle mt24">Services</div>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/products-services/digital-library.html"
                    }
                  >
                    Digital Library
                  </NavDropdown.Item>
                  <div className="level2Menu">
                    <NavDropdown
                      title={
                        <a
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/enterprise-solutions/integrated-services.html"
                          }
                          className="dropdown-item type2"
                        >
                          Enterprise Solutions{" "}
                          <i className=" fas fa-caret-right astm-icon"></i>
                        </a>
                      }
                    >
                      <div
                        className="innerSubMenu"
                        data-menu="Enterprise Solutions"
                      >
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/enterprise-solutions/integrated-services.html"
                          }
                        >
                          Integrated Services
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/enterprise-solutions/astm-compass.html"
                          }
                        >
                          ASTM CompassÂ®
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/enterprise-solutions/specbuilder.html"
                          }
                        >
                          SpecBuilder
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/enterprise-solutions/custom-platforms.html"
                          }
                        >
                          Custom Platforms
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                  </div>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/products-services/proficiency-testing.html"
                    }
                  >
                    Proficiency Testing
                  </NavDropdown.Item>
                  <div className="level2Menu">
                    <NavDropdown
                      title={
                        <a
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/training-courses.html"
                          }
                          className="dropdown-item type2"
                        >
                          Training Courses{" "}
                          <i className=" fas fa-caret-right astm-icon"></i>
                        </a>
                      }
                    >
                      <div
                        className="innerSubMenu"
                        data-menu="Training Courses"
                      >
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/training-courses.html"
                          }
                        >
                          All Training Courses
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/training-courses/international-training.html"
                          }
                        >
                          International Training
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/training-courses/pe-continuing-education.html"
                          }
                        >
                          PE Continuing Education
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/products-services/training-courses/member-training.html"
                          }
                        >
                          Member and Officer Training
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                  </div>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/products-services/certification.html"
                    }
                  >
                    Certification
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={process.env.REACT_APP_PUB_CEMENT_REFERENCE_LAB}
                  >
                    Cement &amp; Concrete Reference Lab
                  </NavDropdown.Item>
                </div>
                <div className="">
                  <ul>
                    <li className="mt8">
                      <a href={process.env.REACT_APP_BOOK_STANDARDS_URL}>
                        <img
                          src={
                            "https://www.astm.org/media/wysiwyg/2025-BOS-Cover.jpg"
                          }
                          alt="2025 Annual Book of ASTM Standards"
                        />
                      </a>
                    </li>
                    <li className="mt24">
                      <a
                        href={
                          process.env.REACT_APP_PUB_URL +
                          "/products-services/enterprise-solutions/astm-compass.html"
                        }
                      >
                        <img
                          src={
                            process.env.REACT_APP_PUB_URL +
                            "/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/compass-new-logo.png"
                          }
                          alt="ASTM Compass Platform displayed on a laptop"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </NavDropdown>
            <NavDropdown
              data-testid="nav-top-2"
              title="Get Involved"
              id="basic-nav-dropdown-2"
            >
              <div data-testid="nav-child-2" className="flex">
                <div>
                  <div className="menuTitle">Membership &amp; Meetings</div>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/get-involved/technical-committees.html"
                    }
                  >
                    Technical Committees
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/get-involved/membership.html"
                    }
                  >
                    Membership
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={process.env.REACT_APP_GLOBAL_MEETING_SYMPOSIA_URL}
                  >
                    Meetings &amp; Symposia
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/get-involved/students-and-professors/for-students/memstudent22.html"
                    }
                  >
                    Students &amp; Professors
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/get-involved/publish-with-astm.html"
                    }
                  >
                    Publish With ASTM
                  </NavDropdown.Item>
                </div>
                <div>
                  <ul>
                    <li className="mt8">
                      <a
                        href={
                          process.env.REACT_APP_PUB_URL +
                          "/get-involved/membership.html"
                        }
                      >
                        <img
                          src={
                            process.env.REACT_APP_PUB_URL +
                            "/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/get-involved-nav.jpg"
                          }
                          alt="Four businesspeopled standing around each other talking"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </NavDropdown>
            <NavDropdown
              data-testid="nav-top-3"
              title="About"
              id="basic-nav-dropdown-3"
            >
              <div data-testid="nav-child-3" className="flex">
                <div>
                  <div className="menuTitle">Overview &amp; Key Facts</div>
                  <div className="level2Menu">
                    <NavDropdown
                      title={
                        <a
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/about/overview.html"
                          }
                          className="dropdown-item type2"
                        >
                          Overview{" "}
                          <i className=" fas fa-caret-right astm-icon"></i>
                        </a>
                      }
                    >
                      <div className="innerSubMenu" data-menu="Overview">
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/about/overview.html"
                          }
                        >
                          Overview
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/about/overview/detailed-overview.html"
                          }
                        >
                          Detailed Overview
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/about/overview/fact-sheet.html"
                          }
                        >
                          Fact Sheet
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/about/overview/white-papers.html"
                          }
                        >
                          White Papers
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href={
                            process.env.REACT_APP_PUB_URL +
                            "/about/overview/corporate-citizenship.html"
                          }
                        >
                          Corporate Citizenship
                        </NavDropdown.Item>
                      </div>
                    </NavDropdown>
                  </div>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL + "/about/governance.html"
                    }
                  >
                    Governance
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/about/global-cooperation.html"
                    }
                  >
                    Global Cooperation Home
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_SN_LINK +
                      "/?q=presidents-column"
                    }
                  >
                    President's Column
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={
                      process.env.REACT_APP_PUB_URL +
                      "/about/diversity-inclusion.html"
                    }
                  >
                    Diversity, Equity, and Inclusion
                  </NavDropdown.Item>
                </div>
                <div>
                  <ul>
                    <li className="mt8">
                      <a
                        href={
                          process.env.REACT_APP_PUB_URL + "/about/global.html"
                        }
                      >
                        <img
                          src={
                            process.env.REACT_APP_PUB_URL +
                            "/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/about-nav.png"
                          }
                          alt="Digital map of the world"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </NavDropdown>
            <NavDropdown
              data-testid="nav-top-4"
              title="News"
              className="newsLink"
              id="basic-nav-dropdown-4"
            >
              <div data-testid="nav-child-4" className="flex">
                <div>
                  <div className="menuTitle">News</div>
                  <NavDropdown.Item href={process.env.REACT_APP_PUB_SN_LINK}>
                    Standardization News
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href={process.env.REACT_APP_PUB_NEWSROOM_LINK}
                  >
                    News Releases
                  </NavDropdown.Item>
                </div>
                <div>
                  <ul>
                    <li className="mt8">
                      <a href="https://www.standardizationnews.com/standardizationnews/march_april_2021/MobilePagedArticle.action?articleId=1664626">
                        <img
                          src={
                            process.env.REACT_APP_PUB_URL +
                            "/static/version1633442940/frontend/ASTM/default/en_US/Alpine_CmsContent/images/nav/MA21-SN-Cover-sm.jpg"
                          }
                          alt="Standardization News magazine cover"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </NavDropdown>
            <RenderMobileMenu />
            <NavDropdown
              title="Site Search"
              id="basic-nav-dropdown2"
              className="mobileSite"
            >
              <div className="commonLoader show">
                <div className="siteSearch">
                  <SearchBox />
                </div>
              </div>
            </NavDropdown>
          </Nav>
        </Navbar>
        <div className="menuWrapper">
          <ul>
            <li>
              <a href={process.env.REACT_APP_PUB_URL + "/contact/"}>Contact</a>
            </li>
            {!props?.featureFlag.wcmsnavintegration && (
              <li>
                <a href={process.env.REACT_APP_PUB_URL + "/checkout/cart/"}>
                  Cart
                </a>
              </li>
            )}
          </ul>
          {!props?.featureFlag.wcmsnavintegration && !isEmpty(props.userInfo) ? (
            <MyAccountHeader
              data-testid="astm-account-button-widget"
              userName={
                isEmpty(props.userInfo.demographyDetails)
                  ? ""
                  : memberNickFormat(
                      props.userInfo.demographyDetails.nickname,
                      props.userInfo.demographyDetails.firstname
                    )
              }
              globalLinksURL={{
                profile: process.env.REACT_APP_GLOBAL_PROFILE_URL,
                membership: process.env.REACT_APP_MEMBER_APP_URL + "/MyASTM",
                myCommitteesUrl:
                  process.env.REACT_APP_MEMBER_APP_URL + "/MyASTM/MyCommittees",
                compass: process.env.REACT_APP_GLOBAL_COMPASS_URL,
                orders: process.env.REACT_APP_GLOBAL_ORDERS_URL,
                tracker: process.env.REACT_APP_GLOBAL_TRACKERS_URL,
                elearning: process.env.REACT_APP_E_LEARNING_URL,
                specbuilder:
                  process.env.REACT_APP_IS_SPB_ENABLED === "true"
                    ? process.env.REACT_APP_SPECBUILDER_URL
                    : "",
              }}
              signout={props.signOut}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TopMenu;